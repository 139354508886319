import React, { useMemo } from 'react'
import { window } from 'browser-monads-ts'
import { withTheme } from 'styled-components'

import {
  titleCase,
  stringNotEmpty,
  ensureArray,
  ensureObject,
  convertFormatted,
  DATE_FORMAT_MED,
  DATE_FORMAT_ORGANIZED,
} from '@agnostack/lib-core'

import TemplatedPage from '../components/molecules/TemplatedPage'
import { SEO, NavigationScreenshots, ShareButton } from '../components/atoms'
import { useDimensions } from '../hooks'
import { getTitle } from '../gatsby'

export default withTheme(({ theme, pageContext }) => {
  const [{ width }] = useDimensions(window)
  const {
    node: {
      frontmatter: {
        date,
        category,
        format: pageFormat,
        canonicalPath,
        imagePath,
        parentPath,
        path,
        keywords,
        description,
        hashtags,
        template,
        author = 'https://www.linkedin.com/in/adamgrohs/',
        siteMetadata: {
          title: siteTitle,
          sitePublisher,
          siteUrl,
          tagline,
        } = {},
      } = {},
    } = {},
    paths = [],
  } = ensureObject(pageContext)

  const { breakpoints } = theme || {}
  const { xs: breakpointPx = '', numeric } = breakpoints || {}
  const breakpoint = numeric(breakpointPx)

  const articleCategory = category || 'Press Release'
  const articleKeywords = `press release, news${keywords ? `, ${keywords}` : ''}`
  const articleDate = convertFormatted({
    value: date,
    from: { format: DATE_FORMAT_MED },
    to: { format: DATE_FORMAT_ORGANIZED },
  })

  const title = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _title = getTitle({ context: pageContext })
    return _title === titleCase(template) ? `${_title}: ${tagline}` : _title
  }, [pageContext, template, tagline])

  // NOTE: temp for press release w/ '/Zendesk' that was breaking funny on mobile
  const headline = width < breakpoint ? title.replace('/', '/ ') : title

  const navigation = (
    (paths[category].length <= 1)
      ? null
      : (
        <NavigationScreenshots
          title="agnoStack In The News"
          maxItems={4}
          path={(stringNotEmpty(parentPath) && stringNotEmpty(path)) ? path : ensureObject(ensureArray(paths[category])[0]).path}
          subPaths={paths[category]}
        />
      )
  )

  const pagePath = stringNotEmpty(canonicalPath)
    ? canonicalPath
    : path

  const CTA = !pagePath ? null : () => (
    <ShareButton
      url={`${siteUrl}${pagePath}`}
      subject={title}
      hashtags={hashtags || [siteTitle]}
      shareType="Press Release"
    />
  )

  return (
    <>
      <SEO
        title={title}
        description={description}
        keywords={articleKeywords}
        parentPath={parentPath}
        path={path}
        imagePath={imagePath}
        canonicalPath={canonicalPath}
        type="article"
        {...{
          'article:author': author,
          'article:opinion': 'false',
          'article:content_tier': 'free',
          ...articleCategory && {
            'article:section': articleCategory,
          },
          ...keywords && {
            'article:tag': keywords,
          },
          ...articleDate && {
            'article:published_time': articleDate,
            'article:modified_time': articleDate,
          },
          ...sitePublisher && {
            'article:publisher': sitePublisher,
          },
        }}
      />
      <TemplatedPage
        data={pageContext}
        headline={headline}
        CTA={CTA}
        tagline={articleCategory}
        format={pageFormat || 'medium'}
        navigation={navigation}
      />
    </>
  )
})
